<template>
  <div class="login">
    <div class="login_title">
      <p>
        <!-- <img src="@/assets/images/system/logo-280x280.png" style="width:80px;height:80px;margin-right:30px;" /> -->
        {{ systemName }}
      </p>
    </div>
    <div class="login_main">
      <!-- <div class="main_left" v-if="isWindows"></div> -->
      <div class="main_right" :style="{ width: mainRightWidth }">
        <div class="right_title">用户登录</div>
        <form @submit.prevent="loginSubmit">
          <div class="username">
            <img src="@/assets/images/system/username.png" alt="">
            <input type="text" v-model="loginForm.userName" :style="{ width: mainInputWidth }" placeholder="请输入用户名" />
          </div>
          <div class="password">
            <img src="@/assets/images/system/password.png" alt="">
            <input type="password" v-model="loginForm.passWord" :style="{ width: mainInputWidth }"
              placeholder="请输入密码" />
          </div>
          <div class="yes_login">
            <button type="submit" :style="{ width: mainInputWidth }">登&nbsp;&nbsp;&nbsp;&nbsp;录</button>
          </div>
          <center>
            <p style="margin-top:5vh;font-size:12px;color:#FA6F03;">登录遇到问题，请联系管理员</p>
          </center>
        </form>
      </div>
    </div>
    <div class="login_footer">
      <p class="name">版权所有：{{ company }}</p>
      <p>建议浏览器：IE8及以上、360、谷歌、Firefox v22</p>
      <p>技术支持：{{ support }} &nbsp;&nbsp;&nbsp;&nbsp;联系电话：{{ phone }} &nbsp;&nbsp;&nbsp;&nbsp;备案号：<a class="link"
          href="https://beian.miit.gov.cn/" target="_blank">{{ recordNumber }}</a></p>
    </div>
  </div>
</template>

<script>
import { login, getRouteList } from "@/common/api/login/index";
import { SYSTEM_NAME, COMPANY,SUPPORT, PHONE, RECORD_NUMBER } from "@/common/constant/Const";
import { refreshRoutes } from '@/router';

export default {
  data() {
    return {
      systemName: SYSTEM_NAME,
      company: COMPANY,
      support: SUPPORT,
      phone: PHONE,
      recordNumber: RECORD_NUMBER,
      loginForm: {
        userName: '',
        passWord: '',
        // tenantId: '',
      },
      loading: false,
      // registerForm: {
      //   userName: '',
      //   passWord: '',
      // },
      // paging: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   searchVal: null,
      // },
      pagingData: {
        currentPage: "",
        pageSize: "",
        total: "",
        totalPage: "",
        totalList: [],
      },
      isWindows: true,
      // rules: {
      //   userName: [
      //     { required: true, trigger: "blur", message: "请输入用户名" },
      //   ],
      //   passWord: [{ required: true, trigger: "blur", message: "请输入密码" }],
      //   tenantId: [{ required: true, trigger: "blur", message: "请输入租户" }],
      // },
      // routeParams: {
      //   currentPage: 1,
      //   pageSize: 100,
      //   searchVal: null,
      //   userId: null,
      // },
      mainRightWidth: '23vw',
      mainInputWidth: '15vw',
    };
  },
  mounted() {
    this.checkUserAgent();
  },
  methods: {
    checkUserAgent() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Windows') || userAgent.includes('Win')) {
        console.log("win")
      } else {
        console.log("other")
        this.isWindows = false;
        this.mainRightWidth = '70vw';
        this.mainInputWidth = '53vw';
      }
    },
    loginSubmit() {
      if (!this.loginForm.userName || !this.loginForm.passWord) {
        this.$message.error('请输入用户名和密码');
        return;
      }
      this.loading = true;
      login(this.loginForm).then((resp) => {
        if (resp.data) {
          window.sessionStorage.setItem("token", resp.data.token);
          window.sessionStorage.setItem("userInfo", JSON.stringify(resp.data));
          this.$store.dispatch('updatUserInfo', resp.data);
          getRouteList().then((result) => {
            if (result) {
              const menuList = [{
                "id": 1,
                "menuName": "首页",
                "parentId": 0,
                "orderNum": 1,
                "path": "dashboard",
                "component": "Dashboard",
                "query": null,
                "isFrame": 1,
                "isCache": 0,
                "menuType": "M",
                "visible": "0",
                "status": "0",
                "icon": "s-home",
                "sysMenuType": null,
                "children": [

                ]
              }];
              if (result.data && result.data.length > 0) {
                result.data.forEach(item => {
                  menuList.push(item);
                });
              }
              window.sessionStorage.setItem("menuList", JSON.stringify(menuList));
              this.$store.dispatch('updateMenuList', menuList);
              refreshRoutes();
              this.$router.replace('/dashboard');
            }
            this.loading = false;
          }).catch(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
          this.$message.error(resp.msg);
        }
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
/* CSS 初始化 */
html,
body,
ul,
li,
ol,
dl,
dd,
dt,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
img {
  margin: 0;
  padding: 0;
}

fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

ul,
ol {
  list-style: none;
}

input {
  padding-top: 0;
  padding-bottom: 0;
}

select,
input {
  vertical-align: middle;
}

select,
input,
textarea {
  font-size: 14px;
  margin: 0;
}

textarea {
  resize: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

body {
  font-family: 'Microsoft YaHei', Arial, "\5b8b\4f53";
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-decoration: none;
  font-weight: normal;
  font-size: 100%;
}

/* Login 样式 */
.login {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/images/login/bj.jpg);
  background-size: cover;
}

.login>.login_title {
  height: 11vh;
  text-align: center;
  margin-top: 15vh;
}

.login>.login_title>p {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin-top: -8px;
  letter-spacing: 5px;
}

.login>.login_main {
  // width: 66vw;
  width: 23vw;
  height: 48vh;
  margin: 20px auto 0;
}

.login>.login_main>.main_left {
  height: 48vh;
  width: 43vw;
  float: left;
  border-radius: 10px 0 0 10px;
  background-color: white;
  opacity: 0.5;
}

.login>.login_main>.main_right {
  height: 48vh;
  width: 23vw;
  float: right;
  background-color: #e9eef266;
  border-radius:10px;
}

.login>.login_main>.main_right>.right_title {
  color: #251E5F;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  background: url("@/assets/images/system/border_img.png") repeat;
  margin-bottom: 25px;
}

.login>.login_main>.main_right>form>div {
  position: relative;
}

.login>.login_main>.main_right>form>div>img {
  position: absolute;
  left: 62px;
  top: 1vh;
}

.login>.login_main>.main_right>form input {
  height: 5vh;
  width: 15vw;
  border: 1px solid #d3d8dc;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-left: 50px;
  border-radius: 3px;
  color: #333;
  padding-left: 40px;
}

.login>.login_main>.main_right>form>.yes_login>button {
  display: block;
  width: 15vw;
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  background-color: #0064c8;
  border-radius: 3px;
  color: #fff;
  margin-left: 50px;
  border: none;
  cursor: pointer;
}

.login>.login_main>.main_right>form>.yes_login>button:hover {
  background-color: #0058af;
}

.yes_login {
  margin-left: 1vw;
  margin-top: 1vh;
}

.login>.login_footer {
  text-align: center;
  padding-top: 10vh;
}

.login>.login_footer>p {
  font-size: 14px;
  color: #eee;
  line-height: 30px;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover,
.link:active {
  text-decoration: none;
}

.username {
  margin-left: 1vw;
  margin-top: 5vh;
}

.password {
  margin-left: 1vw;
  margin-top: 1vh;
}
</style>
