import { Message } from 'element-ui';
import axios from "axios";
import router from '@/router';
import { AUTHORIZATION, BEARER } from "@/common/constant/Const";
import Vue from 'vue'
let vm = new Vue()

//请求拦截器
axios.interceptors.request.use(function (config) {
    //请求前做的事
    const token = window.sessionStorage.getItem("token");
    if (token) {
        config.headers[AUTHORIZATION] = BEARER + token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
})

// 响应拦截器
axios.interceptors.response.use(function (resp) {
    // 响应数据处理
    if (resp.data.msg) {
        if (resp.data.code == '0' || resp.data.code == "1") {
            // Message.success({ message: resp.data.msg });
        } else {
            // Message.error({ message: resp.data.msg });
        }
    }
    setTimeout(() => {
      vm.$translate.execute()
    }, 100)
    return resp.data;
}, function (error) {
    if (error.response) {
        // console.log("error");
        // 检查 error.response 是否存在
        if (error.response.status === 504 || error.response.status === 404) {
            Message.error({ message: '服务找不到...' });
        } else if (error.response.status === 403) {
            Message.error({ message: '权限不足，请联系管理员！' });
        } else if (error.response.status === 401) {
            window.sessionStorage.removeItem('token');
            window.sessionStorage.removeItem('userInfo');
            window.sessionStorage.removeItem('menuList');
            Message.error({ message: '尚未登录，请登录！' });
            router.replace('/login');
        } else {
            if (error.response.data.msg) {
                Message.error({ message: error.response.data.msg });
            } else {
                Message.error({ message: '未知错误！' });
            }
        }
    } else {
        Message.error({ message: '网络错误或服务器没有响应！' });
    }
    return Promise.reject(error);
});


let base = '';
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    })
}

export const getRequestRestFul = (url) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
    })
}

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
}
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
}

export const downPostRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        responseType: 'blob',
        // headers: {
        //     "Content-Type": "application/json;charset=UTF-8",
        // },
    })
}
