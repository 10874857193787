<template>
  <div class="footer-main">
    <div class="left-footer-content">
      <span class="text">
        © <span style="margin-right: 20px;">{{ year }}</span> {{ edition }}
        <!-- &nbsp;&nbsp; {{ systemName }} 
        &nbsp;&nbsp; {{ company }} 
        &nbsp;&nbsp; {{ phone }} -->
        <span style="margin-left: 20px;">备案号：<a class="link" href="https://beian.miit.gov.cn/" target="_blank">{{
            recordNumber}}</a></span>
      </span>
    </div>
  </div>
</template>

<script>
import { YEAR, EDITION, SYSTEM_NAME, COMPANY, PHONE, RECORD_NUMBER } from "@/common/constant/Const";
export default {
  name: "CommonFooter",
  components: {},
  data() {
    return {
      year: YEAR,
      edition: EDITION,
      systemName: SYSTEM_NAME,
      company: COMPANY,
      phone: PHONE,
      recordNumber: RECORD_NUMBER,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="less" scoped>
.footer-main {
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-footer-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #fff;
  font-size: 14px;
}

.link {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }
}
</style>
