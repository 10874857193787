<template>
  <div class="header-main">
    <div class="left-header-content">
      <el-button class="menu-button" v-if="!isCollapse" @click="handleMenu" icon="el-icon-s-fold" size="mini"></el-button>
      <el-button class="menu-button" v-else @click="handleMenu" icon="el-icon-s-unfold" size="mini"></el-button>
      <!-- 面包屑 -->
      <breadcrumb class="breadcrumb-container" />
    </div>
    <div class="right-header-content">
      <!-- <div class="header-language" @click="handleLanguage('japanese')"><img
          style="width: 40px; height: 40px;border-radius: 50%;" src="../../assets/images/language/riwenkaiqi.png"></div>
      <div class="header-language" @click="handleLanguage('korean')"><img
          style="width: 40px; height: 40px;border-radius: 50%;" src="../../assets/images/language/hanwenkaiqi.png"></div>
      <div class="header-language" @click="handleLanguage('english')"><img
          style="width: 40px; height: 40px;border-radius: 50%;" src="../../assets/images/language/yingwenkaiqi.png">
      </div>
      <div class="header-language" @click="handleLanguage('chinese_simplified')"><img
          style="width: 40px; height: 40px;border-radius: 50%;" src="../../assets/images/language/zhongwenkaiqi.png">
      </div> -->
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <img class="user-photo" :src="userInfo.avatar?userInfo.avatar: require('@/assets/images/system/1c88f1d1105b867d09ba176846d6bf4.png')" alt="">
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="userCenter">个人中心</el-dropdown-item> -->
          <el-dropdown-item divided command="layout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="个人信息" :visible.sync="dialogVisibleInfo" :before-close="handleClose">
      <el-form :model="userInfo" ref="userInfo">
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="userInfo.userName" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth">
          <el-input v-model="userInfo.nickName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="租户名称" :label-width="formLabelWidth">
          <el-input v-model="userInfo.tenantName" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input v-model="userInfo.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮件地址" :label-width="formLabelWidth">
          <el-input v-model="userInfo.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="userInfo.passWord" autocomplete="off" placeholder="******" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleInfo = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateUserInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb/index.vue";
import { logout } from "@/common/api/login/index";
export default {
  name: "CommonHeader",
  components: {
    Breadcrumb
  },
  data() {
    return {
      dialogVisibleInfo: false,
      formLabelWidth: "120px",
      userInfo: {},
      userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
    };
  },
  methods: {
    handleLanguage(item) {
      if (item !== localStorage.getItem('language')) {
        localStorage.setItem('language', item)
        this.$translate.changeLanguage(localStorage.getItem('language'))
      }
      this.$translate.execute()
    },
    handleMenu() {
      this.$store.commit('collapseMenu');
    },
    handleCommand(command) {
      if (command === 'layout') {
        this.$confirm("此操作将注销登录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            logout().then((resp) => {
              window.sessionStorage.removeItem('token');
              window.sessionStorage.removeItem('userInfo');
              window.sessionStorage.removeItem('menuList');
              this.$router.replace('/login');
            });

          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (command === 'userCenter') {
        this.dialogVisibleInfo = true;
        // getUserInfo().then((resp) => {
        //   this.userInfo = resp.data;
        //   // this.userInfo.tenantId = resp.data.tenantId;
        //   // this.userInfo.userName = resp.data.userName;
        //   // this.userInfo.name = resp.data.name;
        //   // this.userInfo.tenantName = resp.data.tenantName;
        //   // this.userInfo.phone = resp.data.phone;
        //   // this.userInfo.email = resp.data.email;
        // });
      }
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
    },
    onUpdateUserInfo() {
      updateUser(this.userInfo).then((resp) => {
        this.dialogVisibleInfo = false;
      });
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>

<style lang="less" scoped>
.header-main::v-deep {
  padding: 0 20px;
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-header-content {
    display: flex;
    align-items: center;

    .menu-button {
      margin-right: 20px;
    }
  }

  .text {
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
  }

  .right-header-content {
    display: flex;
    align-items: center;

    .header-language {
      color: #fff;
      margin-right: 10px;
    }

    .user-photo {
      widows: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>
