<template>
  <div>
    <!-- 第一层 -->
    <template v-for="item in menuItemData">
      <template v-if="item.visible == 0">
        <!-- 如果第一层有子菜单，则继续循环 -->
        <template v-if="item.children && item.children.length > 0">
          <el-submenu :index="'/'+item.path">
            <template slot="title">
              <i :class="`el-icon-${item.icon}`"></i>
              <span slot="title">{{ isCollapse ? "" : item.menuName }}</span>
            </template>
            <!-- 第二层 -->
            <common-menu-item-aside :menuItemData="item.children"></common-menu-item-aside>
          </el-submenu>
        </template>
        <!-- 如果第一层没有子菜单 -->
        <template v-else>
          <el-menu-item @click="clickMenu(item)" :index="'/'+item.path">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.menuName }}</span>
          </el-menu-item>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import CommonMenuItemAside from "./CommonMenuItemAside.vue";
export default {
  name: "CommonMenuItemAside",
  components: {
    CommonMenuItemAside,
  },
  props: {
    menuItemData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickMenu(item) {
      if (item.redirect) {
        // console.log('有重定向')
      } else {
        // console.log('无重定向',item)
        this.$store.dispatch('updateMenuItem', item);
        if (this.checkRouter({ path: item.path })) {
          // console.log("tiaozhuan", item.path);
          if (item.query && item.query.length > 0) {
            this.$router.push(item.query + '/' + item.path);
          } else {
            this.$router.push('/' + item.path);
          }
        }
      }
    },
    // 当前页面路由与跳转路由不一致时才允许跳转
    checkRouter(data) {
      // console.log(this.$route.path, data, data.path);
      return (
        // 当前路由     要跳转的路由
        // 有重定向（redirect）的时候，当前路由不等于被跳转的路由（最终url）并且
        this.$route.path !== data.path &&
        !(this.$route.path === "/dashboard" && data.path === "dashboard") &&
        !(this.$route.path === "/tenantList" && data.path === "tenantList") &&
        !(this.$route.path === "/userList" && data.path === "userList") &&
        !(this.$route.path === "/roleList" && data.path === "roleList") &&
        !(this.$route.path === "/menuList" && data.path === "menuList") &&
        !(this.$route.path === "/deptList" && data.path === "deptList") &&
        !(this.$route.path === "/logsList" && data.path === "logsList") &&
        !(this.$route.path === "/menuListTenant" && data.path === "menuListTenant") &&
        true
      );
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
