import Vue from 'vue';
import VueRouter from 'vue-router';
import { generateRoutes } from '@/common/utils/generateRoutes';
import Main from '@/views/Main.vue';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const menuList = JSON.parse(window.sessionStorage.getItem("menuList"));
const routesApi = (menuList && generateRoutes(menuList)) || [];

const initialRoutes = [
  {
    path: '/',
    component: Main,
    redirect: '/dashboard',
    children: routesApi
  },
  { path: '/login', name: 'login', component: Login }
];

const router = new VueRouter({
  // mode: 'history',
  routes: initialRoutes
});

// 定义刷新路由函数
export function refreshRoutes() {
  const menuList = JSON.parse(window.sessionStorage.getItem("menuList"));
  const routesApi = (menuList && generateRoutes(menuList)) || [];
  const newRoutes = [
    {
      path: '/',
      component: Main,
      redirect: '/dashboard',
      children: routesApi
    },
    { path: '/login', name: 'login', component: Login }
  ];

  // 创建一个新的 VueRouter 实例，仅用于重置 matcher
  const newRouter = new VueRouter({
    // mode: 'history', // 如果需要使用 HTML5 History 模式，可以取消注释
    routes: newRoutes
  });

  // 重置 router.matcher 以强制更新路由
  router.matcher = newRouter.matcher;

  // 更新路由选项
  router.options.routes = newRoutes;
}

export default router;
