<template>
  <div class="sidebar">
    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
      :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router :unique-opened="true">
      <div class="logo" unique-opened>
        <h3 v-if="!isCollapse">{{ systemName }}</h3>
        <h3 v-else><el-image style="width: 40px; height: 40px; margin-top: 20px;" :src="logoImg" alt="logo"
            fit="cover"></el-image></h3>
      </div>
      <common-menu-item-aside :menuItemData="menuData"></common-menu-item-aside>
    </el-menu>
  </div>
</template>

<script>
import { SYSTEM_NAME } from "@/common/constant/Const";
import CommonMenuItemAside from "./CommonMenuItemAside.vue";
import { mapState } from 'vuex';
import logoImg from '@/assets/logo.png'
export default {
  name: "CommonMenuAside",
  components: {
    CommonMenuItemAside,
  },
  data() {
    return {
      systemName: SYSTEM_NAME,
      logoImg: logoImg,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(this.$route);
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(this.$route);
      // console.log(key, keyPath);
    },
  },
  computed: {
    //没有子菜单
    noChildren() {
      return this.menuData.filter((item) => !item.children);
    },
    //有子菜单
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    // ...mapState({
    //   menuData: state => state.menuList || []
    // }),
    menuData() {
      // return this.$store.state.tab.menuList &&
      //   this.$store.state.tab.menuList.length > 0
      //   ? this.$store.state.tab.menuList
      //   : JSON.parse(window.sessionStorage.getItem("menuList"));
      return this.$store.state.tab.menuList;
    },
  },
  mounted() {
    const storedMenuList = JSON.parse(window.sessionStorage.getItem("menuList"));
    if (storedMenuList) {
      this.$store.dispatch('updateMenuList', storedMenuList);
    }
  }
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100vh;
  // width: 200px;
  border-right: none;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}

.logo {
  height: 60px;
  background-color: #545c64;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
