import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { deleteRequest, downPostRequest, getRequest, postRequest, putRequest } from '@/common/config/request'

import router from './router'
import store from './store'

//需要挂载到Vue原型上
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.downPostRequest = downPostRequest;

Vue.use(ElementUI);

import plugins from './plugins' // plugins
Vue.use(plugins)

Vue.config.productionTip = false

import translate from 'i18n-jsautotranslate'
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
Vue.prototype.$translate = translate

router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('token');
  if (!token && to.name !== 'login') {
    next({ name: 'login' });
  } else if (token && to.name === 'login') {
    next({ path: '/dashboard' });
  } else {
    next();
  }
})

// 上传图片组件
import ImageUpload from '@/components/ImageUpload'
// 文件上传组件
import FileUpload from '@/components/FileUpload'
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 富文本组件
import Editor from "@/components/Editor"
// 全局组件挂载
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('FileUpload', FileUpload)
Vue.component('Editor', Editor)

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
