import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const generateRoutes = (data) => {
    // console.log(data);
    let fmRoutes = []; // 初始化空数组用于存储路由配置
    data.forEach(item => {
        // 解构赋值从item中获取所需属性
        let {
            menuName, // 菜单名称
            path, // 路由路径
            icon, // 图标
            hidden, // 是否隐藏
            isFrame, // 是否内嵌
            isCache, // 是否缓存
            component, // 组件路径
            redirect, // 重定向路径
            visible, // 可见性
            status, // 状态
            children = [] // 子路由，默认为空数组
        } = item;

        // 如果是内嵌网页，则不添加进路由（取消注释以下代码以启用此逻辑）
        // if (isFrame === 1) {
        //     return;
        // }
        // redirect = component ? "" : "/" + path;

        // 处理路径，去除前导斜杠
        let fmRouter = {
            path: component ? (path.startsWith('/') ? path.substring(1) : path) : "",
            name: menuName,
            redirect: redirect,
            icon: icon,
            hidden: hidden,
            //hidden: visible === "0", // 后台拉取如果有hidden为0的就是不显示
            //isCache: !isCache, // 是否缓存标志

            // 动态加载组件
            component: component ? (() => {
                // 处理组件路径，去除前导斜杠
                component = component.startsWith('/') ? component.substring(1) : component;
                if (component.endsWith('.vue')) {
                    // 如果组件路径以'.vue'结尾，去除扩展名
                    component = component.replace(/\.vue$/, '');
                }

                // 动态引入组件
                // require(['@/views/' + component], resolve);
                return () => import('@/views/' + component + '.vue');
            })() : (
                // 渲染了一个 router-view 组件，这个组件用于占位，表示在这个位置会显示子路由的内容
                { render: h => h('router-view') }
            ),

            // 递归生成子路由
            children: children && children.length > 0 ? generateRoutes(children) : children,
        };
        // 添加生成的路由到数组中
        fmRoutes.push(fmRouter);
    });

    return fmRoutes; // 返回生成的路由配置数组
}



// export const resetRouter = (router, data) => {
//     // 定义新的路由配置
//     const routes = [
//         {
//             path: '/', // 根路径
//             component: () => import('@/views/Main.vue'), // 动态加载 Main.vue 组件
//             redirect: '/dashboard', // 重定向到 /dashboard
//             children: data // 将传入的 data 作为子路由
//         },
//         {
//             path: '/login', // 登录路径
//             name: 'login', // 路由名称
//             component: () => import('@/views/Login.vue') // 动态加载 Login.vue 组件
//         },
//     ];

//     // 创建一个新的 VueRouter 实例，仅用于重置 matcher
//     const newRouter = new VueRouter({
//         // mode: 'history', // 使用 HTML5 History 模式
//         // routes: [] // 临时设置为空的路由
//         routes
//     });

//     // 将传入 router 的 matcher 重置为新的 router 的 matcher
//     router.matcher = newRouter.matcher;
//     // 动态添加新的路由配置
//     // router.addRoutes(routes);
//     // 强制更新路由
//     router.options.routes = newRouter.options.routes;
// };