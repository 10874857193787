<template>
  <div id="container">
    <el-container>
      <el-aside width="auto">
        <common-menu-aside />
      </el-aside>
      <el-container>
        <el-header>
          <common-header />
        </el-header>
        <el-main style="height:80vh">
          <router-view></router-view>
        </el-main>
        <el-footer>
          <common-footer />
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonMenuAside from "../components/menu/CommonMenuAside.vue";
import CommonHeader from "../components/header/CommonHeader.vue";
import CommonFooter from "../components/footer/CommonFooter.vue";
export default {
  data() {
    return {
    };
  },
  components: {
    CommonMenuAside,
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style lang="less" scoped>
#container{
  background-color: #f4f4f4;
}
.el-header {
  padding: 0;
}

.el-footer {
  padding: 0;
}

.el-main{
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  background-color: #fff;
}
</style>
