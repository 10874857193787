import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

//创建vuex实例
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tab
  },
  plugins: [createPersistedState({
    // 配置选项
    storage: window.localStorage, // 或者使用 sessionStorage
    paths: ['tab'] // 指定要持久化的模块
  })]
})