import { generateRoutes } from '@/common/utils/generateRoutes'

export default {
    state: {
        isCollapse: false,//控制菜单的展开或收起
        menuList: [],
        menuItem: {},
        userInfo: {},
    },
    mutations: {
        //修改菜单展开或收起方法
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse;
        },
        setMenuList(state, menuList) {
            state.menuList = menuList;
        },
        setMenuItem(state, menuItem){
            state.menuItem = menuItem;
        },
        setUserInfo(state, userInfo){
            state.userInfo = userInfo;
        },
        
    },
    actions: {
        updateMenuList({ commit }, menuList) {
            commit('setMenuList', menuList);
        },
        updateMenuItem({ commit }, menuItem) {
            commit('setMenuItem', menuItem);
        },
        updatUserInfo({ commit }, userInfo) {
            commit('setUserInfo', userInfo);
        }
    }
}