export const AUTHORIZATION = 'Authorization';
export const BEARER = 'Bearer ';
export const EDITION = '版本 1.1.0'; // 版本
export const YEAR = '2024'; // 年
export const SYSTEM_NAME = '每人观（酒业）管理'; ///系统名称
export const COMPANY = '中慧讯科(天津)科技发展有限公司'; // 公司名
export const SUPPORT = '中慧讯科(天津)科技发展有限公司';
export const PHONE = '15022500877'; // 联系方式
export const RECORD_NUMBER = '津ICP备16006359号-1'; //备案号

export const PICTURE_URL = 'https://qiniumeirenguan.banjingkeji.com/'; //图片前缀

export const QUERY = 'QUERY';

export const SYS_BASE_URL = '/pg-app'; // 基础url
export const SYS_BASE_URL_API = '/guanjiu-service';

export const CREATE = 'create';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const UNBIND = 'unbind';
export const BIND = 'bind';
export const UNBIND_API = 'unbindApi';
export const BIND_API = 'bindApi';

export const SYS_DEF = "00";